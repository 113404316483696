body {
  margin: 0;
  font-family: Roboto;
}
.ant-btn-primary {
  background-color: #009577 !important;
  border-color: #009577 !important;
}

.ant-btn:active {
  border-color: #009577 !important;
  color: #009577 !important;
}

.ant-switch-checked {
  background-color: #009577 !important;
}

.ant-table-thead > tr > th {
  background-color: white !important;
  color: #009577 !important;
}

.ant-pagination-item-active {
  border-color: #009577 !important;
}

.ant-pagination-item-active a {
  color: #009577 !important;
}

.ant-form-explain {
  position: absolute;
}

/* Because the modal has overlaying input autocomplete */
.ant-select-dropdown {
  z-index: 1000;
}

.infoBox {
  overflow: visible !important;
  width: 140px !important;
  height: 30px !important;
}
