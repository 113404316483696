.tensilPagination > .ant-pagination-prev {
  display: none;
}
.tensilPagination > .ant-pagination-next {
  display: none;
}
.tensilPagination > .ant-pagination-item {
  margin-right: 0;
}
.tensilPagination > .ant-pagination-item-active {
  background-color: #2476ec;
}
.tensilPagination > .ant-pagination-item-active > a {
  color: #fff !important;
}
